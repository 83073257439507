import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "@babel/polyfill"

import Layout from "../layouts"
import Head from "../components/head"

import "../css/single-resource.css"

class SingleResourceTemplate extends Component {
  render() {
    const resource = this.props.data.wpResource
    const { seo, cptResource } = resource

    const handleShareClick = platform => {
      switch (platform) {
        case "facebook":
          document.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              window.location.href
            )}`,
            "",
            "width=626,height=436"
          )
          break
        case "twitter":
          document.open(
            `https://twitter.com/share?text=${encodeURIComponent(
              seo.title
            )}&url=${encodeURIComponent(window.location.href)}`,
            "",
            "width=626,height=300"
          )
          break
        case "linkedin":
          document.open(
            `https://www.linkedin.com/shareArticle?mini=true&url=${
              window.location.href
            }&title=${encodeURIComponent(seo.title)}`,
            "",
            "width=626,height=300"
          )
          break
        case "email":
          window.open(
            `mailto:?subject=Check out - ${encodeURIComponent(
              seo.title
            )}&body=I thought you would like this ${window.location.href}.`,
            "_self"
          )
          break
      }
    }

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />
        <div className="container baseContainerWrapper single-resource-container">
          <div className="baseContainer">
            {/* <div className="breadcrumbs">
              <a href="/">roanokegroup.com</a>
              &nbsp;&nbsp;»&nbsp;&nbsp;
              <a href="/resource-center/">resource center</a>
              &nbsp;&nbsp;»&nbsp;&nbsp;
              <a href="/resource-center/tools-training/">tools & training</a>
              &nbsp;&nbsp;»&nbsp;&nbsp;
              <a href="/resource-center/tools-training/reference-library/">
                reference library
              </a>
              &nbsp;&nbsp;»&nbsp;&nbsp;
              {seo.title}
            </div> */}
            <div className="resource-wrapper">
              {cptResource.previewimage && (
                <img
                  className="resource-image"
                  src={cptResource.previewimage.localFile.publicURL}
                />
              )}
              <div className="resource-content">
                <h1>{resource.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: resource.content }} />
                <div className="resource-actions">
                  {cptResource.file && (
                    <a
                      href={cptResource.file.localFile.publicURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="resource-btn"
                    >
                      Download PDF
                    </a>
                  )}
                  <div className="resource-share">
                    SHARE THIS:&nbsp;&nbsp;
                    <button
                      type="button"
                      className="share-icon"
                      onClick={() => handleShareClick("facebook")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                      </svg>
                    </button>
                    <button
                      type="button"
                      className="share-icon"
                      onClick={() => handleShareClick("twitter")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                      </svg>
                    </button>
                    <button
                      type="button"
                      className="share-icon"
                      onClick={() => handleShareClick("linkedin")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                      </svg>
                    </button>
                    <button
                      type="button"
                      className="share-icon"
                      onClick={() => handleShareClick("email")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="other-resources">
              <div className="maincopy">
                <h2>Other Resources</h2>
                <hr />
              </div>
              <div className="other-resources-grid">
                {this.props.data.allWpResource?.nodes?.map(
                  ({ id, title, featuredImage, link, cptResource }) => (
                    <div key={id} className="other-resources-item">
                      <a href={link}>
                        <img
                          src={featuredImage.node.localFile.publicURL}
                          alt={featuredImage.node.altText}
                        />
                      </a>
                      <div className="other-resources-item-content">
                        <div>
                          <h3>
                            <a href={link}>{title}</a>
                          </h3>
                          {cptResource.type && <div>{cptResource.type}</div>}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="other-resources-actions">
                <a
                  href="/resource-center/tools-training/reference-library"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="resource-btn"
                >
                  Contact Us
                </a>
                <a
                  href="/contact-us"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="resource-btn"
                >
                  Back to Library
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default SingleResourceTemplate

SingleResourceTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query ($id: String!, $resourceCategoryId: String = "") {
    wpResource(id: { eq: $id }) {
      title
      content
      uri
      id
      seo {
        ...seoParts
      }
      cptResource {
        file {
          localFile {
            publicURL
          }
        }
        previewimage {
          localFile {
            publicURL
          }
        }
      }
    }

    allWpResource(
      filter: {
        status: { eq: "publish" }
        resourceCategories: {
          nodes: { elemMatch: { id: { eq: $resourceCategoryId } } }
        }
      }
      limit: 100
    ) {
      nodes {
        id
        title
        link
        resourceCategories {
          nodes {
            name
          }
        }
        cptResource {
          type
        }
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
